<template>
  <b-overlay :show="loading">
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <img
              src="../../../../public/assets/logo-qiwari.png"
              alt=""
              style="width: 130px"
            />
          </b-link>

          <b-card-title class="mb-1 text-center">
            Selamat Datang di {{ appName }}
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Silahkan masuk dengan akun anda dan atur bisnismu!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label-for="email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-flex justify-content-between">
                  <label for="password"></label>
                  <b-link :to="{ path: 'forgot-password' }">
                    <small>Lupa Password?</small>
                  </b-link>
                </div>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="info"
                type="submit"
                class="gradient-balanja border border-0"
                block
                :disabled="!isValidForm"
                @click.prevent="submit"
              >
                <strong>{{ loginLabel }}</strong>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Belum punya akun? </span>
            <a href="https://balanja.id/daftar">
              <span>Daftar Sekarang</span>
            </a>
          </b-card-text>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BOverlay,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import { required, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    // BSV
    BButton,
    BOverlay,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: null,
      password: null,
      status: "",
      // validation rules
      required,
      email,
      loading: false,
      loginLabel: "LOGIN",
    };
  },
  computed: {
    appLogoImage() {
      return $themeConfig.app.appLogoImage;
    },
    appName() {
      return $themeConfig.app.appName;
    },
    appFullName() {
      return $themeConfig.app.appFullName;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    isValidForm() {
      if (!this.password || !this.userEmail) {
        return false;
      }

      return true;
    },
  },
  methods: {
    submit() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          try {
            this.loading = true;
            this.loginLabel = "LOADING...";
            const response = await useJwt.login({
              email: this.userEmail,
              password: this.password,
            });
            let {
              data: userData,
              access: accessMenu,
              type: typeToken,
              tokens: token,
            } = response.data;

            if (!userData.level) {
              this.displayError({
                message: "User tidak terdaftar",
              });

              return false;
            }
            useJwt.setToken({ type: typeToken, token });

            // get role user
            const roles = await this.$store.dispatch("role/getData", {
              level_id: userData.level.id,
            });
            if (!roles) {
              this.displayError({
                message: "User belum memiliki akses",
              });
              return false;
            }
            const myMenus = [];
            accessMenu.map((parent) => {
              const payload = {
                title: parent.name,
                icon: parent.icon,
              };

              // parent menu
              if (parent.sub_menu) {
                payload.children = [];
                // loop sub menu
                parent.sub_menu.map((sb) => {
                  const sb_payload = {
                    title: sb.name,
                    // icon: "CircleIcon",
                    icon: sb.icon,
                    route: sb.url,
                    url: sb.url,
                  };

                  if (sb.notifikasi && sb.notifikasi.notif > 0) {
                    sb_payload.tag = sb.notifikasi.notif;
                  }

                  payload.children.push(sb_payload);
                });
              } else {
                // link menu
                payload.route = parent.url;
                payload.url = parent.url;
                // get notifikasi if they > 0
                if (parent.notifikasi && parent.notifikasi.notif > 0) {
                  payload.tag = parent.notifikasi.notif;
                }
              }

              myMenus.push(payload);
            });

            // check if user is admin gudang, get his gudang
            const isAdminGudang =
              userData.level &&
              this.GUDANG_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isCabang =
              userData.level &&
              this.CABANG_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isMarketing =
              userData.level &&
              this.MKT_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isTerapis =
              userData.level &&
              this.TERAPIS_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            if (isAdminGudang || isCabang || isMarketing) {
              const myKaryawanId = userData.karyawan
                ? userData.karyawan.id
                : userData.member_id;

              let payload =
                userData.level.nama_level == "cabang" ||
                userData.level.nama_level == "marketing"
                  ? { member_id: myKaryawanId }
                  : { karyawan_id: myKaryawanId };

              const myGudang = await this.$store.dispatch(
                "gudang/getData",
                payload
              );

              if (!myGudang || myGudang.length < 1) {
                localStorage.clear();
                sessionStorage.clear();
                this.displayError({
                  message: "Akun Gudang belum ditempatkan, hubungi Admin",
                });
                this.loginLabel = "LOGIN";
                return false;
              }

              localStorage.setItem("myGudang", JSON.stringify(myGudang[0]));
            }

            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("accessMenu", JSON.stringify(myMenus));
            localStorage.setItem("userRoles", JSON.stringify(roles));
            this.updateAbility(roles);
            this.loading = false;

            if (this.$route.query.to) {
              const path = this.$route.query.to;
              window.location.href = path;
            } else {
              window.location.reload();
            }
          } catch (e) {
            this.loading = false;
            this.loginLabel = "LOGIN";
            this.displayError(e);
            return false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
